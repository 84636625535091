@use "../../../../../styles/mixins";

.toggleButtonsContainer {
  text-align: right;
  margin-right: 40px;
  padding-bottom: 20px;

  @media(max-width: 500px) {
    display: none;
  }
}

.eventsBlockWrapper {
  @include mixins.opacityTransition(0, 1);
  transition: transform .25s ease;

  > button {
    position: absolute;
    right: 40px;
    top: 55px;
  }

}

.eventsTransitionWrapper {
  @include mixins.translateTransition(translateY(20%), translateY(0));
  @include mixins.opacityTransition(0, 1);
}

.eventsBlock {
  transition: opacity .5s ease, transform .25s ease;
  transform: translateX(-20%);
  opacity: 0;
}
