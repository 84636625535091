@use "../../../../../styles/variables.module" as *;
@use '../../../../../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';

.calendarContainer {
  margin: 0 auto;
}

@media(max-width: $break-point-sm) {
  .calendarContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.viewMoreButton {
  text-align: center;

  @media(max-width: $break-point-sm) {
    padding-top: 0.75rem;
  }
}
