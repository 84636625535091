@use "variables.module" as *;

@mixin loadingAnimation {
  @keyframes placeHolderShimmer{
    0%{
      background-position: -468px 0
    }
    100%{
      background-position: 468px 0
    }
  }

  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);

  > * {
    background: #fff;
    position: absolute;
  }
}

@mixin minScreenXs {
  @media (min-width: $break-point-xs) {
    @content;
  }
}

@mixin screenXs {
  @media (min-width: $break-point-xs) and (max-width: ($break-point-sm - 1)) {
    @content;
  }
}

@mixin minScreenSm {
  @media (min-width: $break-point-sm) {
    @content;
  }
}

@mixin screenSm {
  @media (min-width: $break-point-sm) and (max-width: ($break-point-md - 1)) {
    @content;
  }
}

@mixin minScreenMd {
  @media (min-width: $break-point-md) {
    @content;
  }
}

@mixin screenMd {
  @media (min-width: $break-point-md) and (max-width: ($break-point-lg - 1)) {
    @content;
  }
}

@mixin minScreenLg {
  @media (min-width: $break-point-lg) {
    @content;
  }
}

@mixin maxHeightTransition($amount) { // need to have max-height set to work
  .transitionEnter {
    max-height: 0;
    padding: 0;
    opacity: 0;
    overflow: hidden;
    display: inherit;
  }
  .transitionEnterActive {
    max-height: 0;
    padding: 0;
    overflow: hidden;
  }
  .transitionEnterDone {
    opacity: 1 !important;
    max-height: $amount !important;
  }
  .transitionExit {
    max-height: $amount;
  }
  .transitionExitActive {
    max-height: 0;
  }
  .transitionExitDone {
    opacity: 0;
    display: none;
    max-height: 0;
  }
}

@mixin translateTransition($translate, $base) {
  .transitionEnter {
    transform: $translate;
  }
  .transitionEnterActive {
    transform: $translate;
  }
  .transitionEnterDone {
    transform: $base;
  }
  .transitionAppear {
    transform: $base;
  }
  .transitionAppearActive {
    transform: $translate;
  }
  .transitionAppearDone {
    transform: $base;
  }
  .transitionExit {
    transform: $base;
  }
  .transitionExitActive {
    transform: $translate;
  }
  .transitionExitDone {
    transform: $translate;
  }
}

@mixin opacityTransition($start, $end) {
  .transitionEnter {
    opacity: $start;
  }
  .transitionEnterActive {
    opacity: $start;
  }
  .transitionEnterDone {
    opacity: $end;
  }
  .transitionExit {
    opacity: $end;
  }
  .transitionExitActive {
    opacity: $start;
  }
  .transitionExitDone {
    opacity: $start;
  }
}

@mixin maxWidthTransition($amount) { // need to have max-height set to work
  .transitionEnter {
    max-width: 0;
    padding: 0;
    opacity: 0;
    overflow: hidden;
  }
  .transitionEnterActive {
    max-width: 0;
    padding: 0;
    overflow: hidden;
  }
  .transitionEnterDone {
    opacity: 1;
    max-width: $amount;
  }
  .transitionExit {
    opacity: 0;
    max-width: $amount;
  }
  .transitionExitActive {
    max-width: 0;
  }
  .transitionExitDone {
    max-width: 0;
  }
}

@mixin greenButton {
  background-color: $admin-green-dark;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 1.2rem;
}

@mixin disabledButton {
  opacity: .5;
  pointer-events: none;
  cursor: default;
}

@mixin socialColor($property) {
  &Facebook {
    #{$property}: $facebook-color;
  }
  &Twitter {
    #{$property}: $twitter-color;
  }
  &Pinterest {
    #{$property}: $pinterest-color;
  }
  &Youtube {
    #{$property}: $youtube-color;
  }
  &Instagram {
    #{$property}: $instagram-color;
  }
  &Vimeo {
    #{$property}: $vimeo-color;
  }
  &Calendar {
    #{$property}: $admin-green-dark;
  }
  &Error {
    #{$property}: $error-red;
  }
  &Yelp {
    #{$property}: $yelp-color;
  }
  &Tripadvisor {
    #{$property}: $tripadvisor-color;
  }
  &Rss {
    #{$property}: $rss-color;
  }
  &Google {
    #{$property}: $google-color;
  }
}

@mixin focusOutline {
  outline-width: 5px;
  outline-offset: -2px
}

@mixin buttonColor($backgroundColor, $fontColor) {
  background: $backgroundColor;
  color: $fontColor;

  &:hover, &:focus {
    background: $backgroundColor;
    color: $fontColor;

    &[disabled] {
      background: $backgroundColor;
    }
  }
}

@mixin tooltip() {
  position: relative;
  color: $black;

  &::after, &::before {
    content: "";
    opacity: 0;
    transition: opacity .25s ease;
  }

  &:hover {
    z-index: 999;
    &::after {
      opacity: 1;
      background: black;
      content: attr(data-content);
      position: absolute;
      color: white;
      margin-left: 10px;
      z-index: 1;
      font-size: 1rem;
      padding: .5rem;
      white-space: pre;
      border-radius: 5px;
      transform: translateY(-50%);
      top: 50%;
      text-align: center;
      font-weight: 100;
    }

    &::before {
      content: "";
      opacity: 1;
      background: black;
      width: 10px;
      position: absolute;
      height: 10px;
      right: -15px;
      transform: translateY(-50%) rotate(45deg);
      top: 50%;
    }
  }
}

@mixin meritChatCallout($font-size, $margin-left, $margin-right) {
  font-size: $font-size;
  margin-left: $margin-left;
  margin-right: $margin-right;

  &:hover {
    cursor: pointer;
    padding: 0 $margin-right 0 $margin-left; /* Adjust padding as needed */
    margin-left: 0;
    margin-right: 0;
    animation: shake 0.5s;
    animation-iteration-count: 1;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-3px); }
    50% { transform: translateX(3px); }
    75% { transform: translateX(-3px); }
    100% { transform: translateX(0); }
  }
}

@mixin leftToRightColorTransition($initial-color, $transition-color) {
  color: $initial-color !important;
  background: linear-gradient(to left, $transition-color 50%, $initial-color 50%) right;
  background-size: 200% 100%;
  border: solid 3px $initial-color;
  transition: all .4s ease-in-out;

  &:hover{
    background-position:left;
    color: $transition-color !important;
    border: solid 3px $transition-color;
  }
}

@mixin toggleColorMixin($selected-color, $unselected-color) {
  background: $unselected-color !important;

  div {
    background: $selected-color !important;
  }
  label {
    color: $unselected-color;
  }
  input:checked + label span {
    transition: background-color 1s ease;
    background-color: $selected-color;
  }
  input:not(:checked) + label {
    color: $selected-color !important;
  }

}

@mixin searchBarMixin {
  border-radius: 15px;
  background: #e7e6e6;
  color: $black;
  &:focus-within {
    outline: 5px auto currentColor;
    outline: 5px auto -webkit-focus-ring-color;
  }

  > * {
    // fixes weird Firefox overflow issue where loading spinner overflows container
    min-width: 1px;
  }

  input {
    border: none;
    background: inherit;

    &:focus {
      outline: none;
    }
  }
}



